import React from "react";
import './App.css';


/**************************/
//APP STRUCTURE...
//<MusicHackerApp>
//	<Keyboard>
//		<Keys />
//		<KeyRootNotes />
//		<KeyNotes />
//		<KeyNoteLabels />
//	</Keyboard>
//	<KeyLengthControl />
//	<Fretboard>
//		<FretGrid />
//		<FretRootNotes />
//		<FretNotes />
//		<FretNoteLabels />
//	</Fretboard>
//	<FretLengthControl />
//	<Caption />
//	<TonicControl />
//	<ScaleTypeControl />
//	<ScaleDensityControl />
//	<LabelDensityControl />
//</MusicHackerApp>
//
// EXPLANATION OF VARIABLES
// tonic = I think this is junk??
// tonicName = gets value from first sub-element of either notes_minTonics or notes_majTonics, e.g.: notes_minTonics[10][0] ... (returns A)
// tonicIndex = SHOULD BE: gets value from second sub-element of either notes_minTonics or notes_majTonics, e.g.: notes_minTonics[10][1] ... (returns 9)
/**************************/

function App() {
	
	const captions = [
		['Root notes', 'Root / Diminished Fifth', 'Diminished Triad', 'Diminished 7th','Whole-Half Diminished Scale','Half-Whole Diminished Scale'],
		['Root notes','Root / Fifth','Minor Triad','Minor Pentatonic Scale','Minor (Diatonic) Scale'],
		['Root notes','Root / Fifth','Major Triad','Major Pentatonic Scale','Major (Diatonic) Scale'],
		['Root notes','Root / Augmented Fifth','Augmented Triad','Whole Tone Scale']
	];
	
	
	//const notes_minTonics = ['C','C♯','D','D♯','E♭','E','F','F♯','G','G♯','A','B♭','B'];
	const notes_minTonics = [['C',0,'notes_flat'],['C♯',1,'notes_sharp'],['D',2,'notes_flat'],['D♯',3,'notes_sharp1'],['E♭',3,'notes_flat1'],['E',4,'notes_sharp'],['F',5,'notes_flat'],['F♯',6,'notes_sharp'],['G',7,'notes_flat'],['G♯',8,'notes_sharp'],['A',9,'notes_flat'],['B♭',10,'notes_flat'],['B',11,'notes_sharp']];
	//const notes_majTonics = ['C','D♭','D','E♭','E','F','F♯','G♭','G','A♭','A','B♭','B'];
	const notes_majTonics = [['C',0,'notes_flat'],['D♭',1,'notes_flat'],['D',2,'notes_sharp'],['E♭',3,'notes_flat'],['E',4,'notes_sharp'],['F',5,'notes_flat'],['F♯',6,'notes_sharp1'],['G♭',6,'notes_flat2'],['G',7,'notes_sharp'],['A♭',8,'notes_flat'],['A',9,'notes_sharp'],['B♭',10,'notes_flat'],['B',11,'notes_sharp']];
	
	const notes_flat = ['C','D♭','D','E♭','E','F','G♭','G','A♭','A','B♭','B'];
	const notes_flat1 = ['C','D♭','D','E♭','E','F','G♭','G','A♭','A','B♭','C♭'];
	const notes_flat2 = ['C','D♭','D','E♭','F♭','F','G♭','G','A♭','A','B♭','C♭'];
	const notes_sharp = ['C','C♯','D','D♯','E','F','F♯','G','G♯','A','A♯','B'];
	const notes_sharp1 = ['C','C♯','D','D♯','E','E♯','F♯','G','G♯','A','A♯','B'];
	//const notes_sharp2 = ['B♯','C♯','D','D♯','E','E♯','F♯','G','G♯','A','A♯','B'];
	
	const dim0 = ['','','','','','','','','','','',''];
	const dim1 = [1,'','','','','','','','','','',''];
	const dim2 = [1,'','','','','',5,'','','','',''];
	const dim3 = [1,'','',3,'','',5,'','','','',''];
	const dim4 = [1,'','',3,'','',5,'','',7,'',''];
	const dim5 = [1,'',2,3,'',4,5,'',6,7,'',8];
	const dim6 = [1,2,'',3,4,'',5,6,'',7,8,''];
	
	const min0 = ['','','','','','','','','','','',''];
	const min1 = [1,'','','','','','','','','','',''];
	const min2 = [1,'','','','','','',5,'','','',''];
	const min3 = [1,'','',3,'','','',5,'','','',''];
	const min4 = [1,'','',3,'',4,'',5,'','',7,''];
	const min5 = [1,'',2,3,'',4,'',5,6,'',7,''];
	
	const maj0 = ['','','','','','','','','','','',''];
	const maj1 = [1,'','','','','','','','','','',''];
	const maj2 = [1,'','','','','','',5,'','','',''];
	const maj3 = [1,'','','',3,'','',5,'','','',''];
	const maj4 = [1,'',2,'',3,'','',5,'',6,'',''];
	const maj5 = [1,'',2,'',3,4,'',5,'',6,'',7];
	
	const aug0 = ['','','','','','','','','','','',''];
	const aug1 = [1,'','','','','','','','','','',''];
	const aug2 = [1,'','','','','','','',5,'','',''];
	const aug3 = [1,'','','',3,'','','',5,'','',''];
	const aug4 = [1,'',2,'',3,'',4,'',5,'',6,''];
	
	function getScaleName(scaleType,scaleName) {
		switch (scaleType) {
			case '1' : 
			scaleName = 'dim';
			break;
			case '2' : 
			scaleName = 'min';
			break;
			case '3' : 
			scaleName = 'maj';
			break;
			case '4' : 
			scaleName = 'aug';
			break;
		}
		return scaleName;
	}
	
	function setScaleDensityControl(scaleType,scaleDensity,labelDensity,scaleDensityMax) {
		switch (scaleType) {
			case '1' : 
			scaleDensityMax = '6';
			if (scaleDensity == 6 && labelDensity == 5) labelDensity = 6;
			break;
			case '2' : 
			if (scaleDensity > 5) scaleDensity = 5;
			scaleDensityMax = '5';
			break;
			case '3' : 
			if (scaleDensity > 5) scaleDensity = 5;
			scaleDensityMax = '5';
			break;
			case '4' : 
			if (scaleDensity > 4) scaleDensity = 4;
			scaleDensityMax = '4';
			break;
		}
		return {
			scaleDensity,
			scaleDensityMax,
			labelDensity
		};
	}
	
	class MusicHackerApp extends React.Component {
		constructor(props) {
			super(props);
			this.handleInstrumentChange = this.handleInstrumentChange.bind(this);
			this.handleKeyboardLengthChange = this.handleKeyboardLengthChange.bind(this);
			this.handleFretboardLengthChange = this.handleFretboardLengthChange.bind(this);
			//this.handleTonicChange = this.handleTonicChange.bind(this);
			this.handleTonicIndexChange = this.handleTonicIndexChange.bind(this);
			this.handleScaleTypeChange = this.handleScaleTypeChange.bind(this);
			this.handleScaleDensityChange = this.handleScaleDensityChange.bind(this);
			this.handleLabelTypeChange = this.handleLabelTypeChange.bind(this);
			this.handleLabelDensityChange = this.handleLabelDensityChange.bind(this);
			this.state = {instrument: 'keyboard', keys: 3, frets: 13, tonic: 9, tonicIndex: 10, scaleDensity: 3,  scaleDensityMax: '5', scaleType: '2', scaleName: 'min', labelType: 0, labelDensity: 3};
		}
		
		handleInstrumentChange(instrument) {
			this.setState({instrument});
		}
		
		handleKeyboardLengthChange(keys) {
			this.setState({keys});
		}
		
		handleFretboardLengthChange(frets) {
			this.setState({frets});
		}
		
		//handleTonicChange(tonic) {
		//	this.setState({tonic});
		//}
		
		handleTonicIndexChange(i) {
			let tonicIndex = this.state.tonicIndex;
			let tonic = this.state.tonic;
			let scaleType = this.state.scaleType;
			tonicIndex += parseInt(i);
			while (tonicIndex < 0) tonicIndex += 13;
			while (tonicIndex > 12) tonicIndex -= 13;
			//console.log('166 tonicIndex: '+tonicIndex+', tonic: '+tonic+', scaleType: '+scaleType+', i: '+i);
			if (scaleType == 1 || scaleType == 2) tonic = notes_minTonics[tonicIndex][1];
			else if (scaleType == 3 || scaleType == 4) tonic = notes_majTonics[tonicIndex][1];
			this.setState({tonicIndex});
			this.setState({tonic});
			//console.log('171 handleTonicIndexChange tonicIndex: '+tonicIndex+', tonic: '+tonic);
		}
		
		handleScaleTypeChange(scaleType,scaleName,scaleDensity,scaleDensityMax,labelDensity) {
			let scaleDensityArray = setScaleDensityControl(scaleType,this.state.scaleDensity,this.state.labelDensity);
			scaleDensity = scaleDensityArray.scaleDensity;
			scaleDensityMax = scaleDensityArray.scaleDensityMax;
			labelDensity = scaleDensityArray.labelDensity;
			if(labelDensity > scaleDensity) {
				labelDensity = scaleDensity;
			}
			this.setState({scaleType});
			this.setState({scaleName});
			this.setState({scaleDensity});
			this.setState({scaleDensityMax});
			this.setState({labelDensity});
		}
		
		handleScaleDensityChange(scaleDensity,scaleDensityMax,labelDensity) {
			let scaleDensityArray = setScaleDensityControl(this.state.scaleType,scaleDensity,this.state.labelDensity);
			scaleDensity = scaleDensityArray.scaleDensity;
			scaleDensityMax = scaleDensityArray.scaleDensityMax;
			labelDensity = scaleDensityArray.labelDensity;
			if(labelDensity > scaleDensity) {
				labelDensity = scaleDensity;
				this.handleLabelDensityChange(labelDensity,scaleDensity);
			}
			this.setState({scaleDensity});
			this.setState({scaleDensityMax});
			this.setState({labelDensity});
		}
		
		handleLabelTypeChange(labelType) { 
			this.setState({labelType});
		}
		
		handleLabelDensityChange(labelDensity,scaleDensity,scaleType) { 
			scaleDensity = this.state.scaleDensity;
			scaleType = this.state.scaleType;
			if(scaleType ==='1') { // handles both WH & HW diminished scales with 8 notes...
				if (scaleDensity == 6 && labelDensity == 5) {
					labelDensity = 6;
				}
			}
			this.setState({labelDensity});
		}
		
		
		render() {
			const instrument = this.state.instrument;
			const keys = parseInt(this.state.keys);
			const frets = parseInt(this.state.frets);
			const tonic = this.state.tonic;
			const tonicIndex = parseInt(this.state.tonicIndex);
			//console.log('MusicHackerApp tonicIndex: '+tonicIndex);
			const scaleType = this.state.scaleType;
			const scaleDensity = this.state.scaleDensity;
			const scaleDensityMax = this.state.scaleDensityMax;
			const scaleName = getScaleName(scaleType);
			const scale = eval(scaleName+scaleDensity+[]);
			const labelType = this.state.labelType;
			//console.log('MusicHackerApp labelType: '+labelType);
			const labelDensity = this.state.labelDensity;
			const labelScale = eval(scaleName+labelDensity+[]);
			
			return (
				<div id="appWrapper">
					<InstrumentSelector value={instrument} onInstrumentChange={this.handleInstrumentChange} />
					<Keyboard instrument={instrument} keys={keys} tonic={tonic} tonicIndex={tonicIndex} scale={scale} scaleType={scaleType} labelType={labelType} labelScale={labelScale} labelDensity={labelDensity} />
					<KeyboardLengthControl value={keys} onKeyboardLengthChange={this.handleKeyboardLengthChange} instrument={instrument} />
					<Fretboard instrument={instrument} frets={frets} tonic={tonic} tonicIndex={tonicIndex} labelType={labelType} scale={scale} scaleType={scaleType} labelScale={labelScale} labelDensity={labelDensity} />
					<FretLengthControl value={frets} onFretboardLengthChange={this.handleFretboardLengthChange} instrument={instrument} />
					<BassFretboard instrument={instrument} frets={frets} tonic={tonic} tonicIndex={tonicIndex} labelType={labelType} scale={scale} scaleType={scaleType} labelScale={labelScale} labelDensity={labelDensity} />
					<BassFretLengthControl value={frets} onFretboardLengthChange={this.handleFretboardLengthChange} instrument={instrument} />
					<Caption tonicIndex={tonicIndex} scaleType={scaleType} density={scaleDensity} />
					<TonicControl value={tonicIndex} tonic={tonic} scaleType={scaleType} onTonicIndexChange={this.handleTonicIndexChange} />
					<ScaleTypeControl value={scaleType} onScaleTypeChange={this.handleScaleTypeChange} />
					<ScaleDensityControl value={scaleDensity} max={scaleDensityMax} onScaleDensityChange={this.handleScaleDensityChange} />
					<LabelTypeControl value={labelType} onLabelTypeChange={this.handleLabelTypeChange} />
					<LabelDensityControl value={labelDensity} scaleDensity={scaleDensity} max={scaleDensity} onLabelDensityChange={this.handleLabelDensityChange} />
				</div>
			);
		}
	}
	
	class InstrumentSelector extends React.Component {
		constructor(props) {
			super(props);
			this.handleChange = this.handleChange.bind(this);
		}
		
		handleChange(e) {
			this.props.onInstrumentChange(e.target.value);
		}
		
		render() {
			const instrument = this.props.value;
			
			function isActive(instrument,buttonValue) {  //active state class is not currently used
				let val = '';
				if (buttonValue == instrument) val = ' active';
				return (
					val
				)
			}
			
			return (
				<div id="instrumentSelector">
					<button id="keyboard-button" className={'instrument-button'+isActive(instrument,'keyboard')} value="keyboard" onClick={this.handleChange}></button> <button id="guitar-button" className={'instrument-button'+isActive(instrument,'guitar')} value="guitar" onClick={this.handleChange}></button> <button id="bass-button" className={'instrument-button'+isActive(instrument,'bass')} value="bass" onClick={this.handleChange}></button>
				</div>
			);
		}
	}
	
	class Keyboard extends React.Component {
		constructor(props) {
			super(props);
		}
		render() {
			const instrument = this.props.instrument;
			const keys = this.props.keys;
			const tonicIndex = this.props.tonicIndex;
			const tonic = this.props.tonic;
			//console.log('Keyboard tonic: '+tonic);
			const scale = this.props.scale;
			//console.log('Keyboard scale: '+scale);
			const scaleType = this.props.scaleType;
			//console.log('Keyboard scaleType: '+scaleType);
			const labelType = this.props.labelType;
			//console.log('Keyboard labelType: '+labelType);
			const labelScale = this.props.labelScale;
			const labelDensity = this.props.labelDensity;
			
			function getVisibility(instrument) {
				return (
					(instrument == 'keyboard') ? 'visible' : 'invisible'
				)
			}
			
			return (
				<div id="keyboardContainer" className={getVisibility(instrument)}>
					<svg id="svgKeyboard" className="svgContainer" height="300" width={(keys * 336) + 100}>
						<KeyRow keys={keys} />
						<KeyRootNotes keys={keys} tonic={tonic} scale={scale} scaleType={scaleType} />
						<KeyNotes keys={keys} tonic={tonic} scale={scale} scaleType={scaleType} />
						<KeyNoteLabels keys={keys} tonic={tonic} tonicIndex={tonicIndex} scaleType={scaleType} scale={scale} labelScale={labelScale} labelType={labelType} />
						Sorry, your browser does not support inline SVG.
					</svg>
				</div>
			);
		}
	}
	
	class KeyRow extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const keys = this.props.keys;
			return (
				Array(keys).fill().map((octaveVal, i) =>
					<g stroke="#000000" strokeWidth="2" fill="none" key={'octave'+(i+1)}>
						<rect id={'key_c'+(i+1)} key={'key_c'+(i+1)} x={(336*i)+1+50} y="30" width="48" height="240" />
						<rect id={'key_cSharp'+(i+1)} key={'key_cSharp'+(i+1)} x={(336*i)+29+50} y="30" width="28" height="155" fill="#000000" />
						<rect id={'key_d'+(i+1)} key={'key_d'+(i+1)} x={(336*i)+49+50} y="30" width="48" height="240" />
						<rect id={'key_dSharp'+(i+1)} key={'key_dSharp'+(i+1)} x={(336*i)+89+50} y="30" width="28" height="155" fill="#000000" />
						<rect id={'key_e'+(i+1)} key={'key_e'+(i+1)} x={(336*i)+97+50} y="30" width="48" height="240" />
						<rect id={'key_f'+(i+1)} key={'key_f'+(i+1)} x={(336*i)+145+50} y="30" width="48" height="240" />
						<rect id={'key_fSharp'+(i+1)} key={'key_fSharp'+(i+1)} x={(336*i)+173+50} y="30" width="28" height="155" fill="#000000" />
						<rect id={'key_g'+(i+1)} key={'key_g'+(i+1)} x={(336*i)+193+50} y="30" width="48" height="240" />
						<rect id={'key_gSharp'+(i+1)} key={'key_gSharp'+(i+1)} x={(336*i)+227+50} y="30" width="28" height="155" fill="#000000" />
						<rect id={'key_a'+(i+1)} key={'key_a'+(i+1)} x={(336*i)+241+50} y="30" width="48" height="240" />
						<rect id={'key_aSharp'+(i+1)} key={'key_aSharp'+(i+1)} x={(336*i)+281+50} y="30" width="28" height="155" fill="#000000" />
						<rect id={'key_b'+(i+1)} key={'key_b'+(i+1)} x={(336*i)+289+50} y="30" width="48" height="240" />
					</g>
				)
			);
		}
	}
	
	class KeyRootNotes extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const keys = this.props.keys;
			const tonic = this.props.tonic;
			const scale = this.props.scale;
			const scaleType = this.props.scaleType;
			
			function getVisibility(position,tonic,scale,scaleType) {
				let note = (position - tonic);
				while (note < 0) note += 12;
				//console.log('KeyRootNotes scale[note]: ' + scale[note]);
				return (
					(scale[note] === 1) && (scaleType != '1') && (scaleType != '4') ? 'visible' : 'invisible' // cancels root circle for dim & aug
				)
			}
			
			return (
				// Keyboard notes
				Array(keys).fill().map((octaveVal, i) =>
					<g stroke="#000000" strokeWidth="2" fill="none" key={'keyboardRoots'+(i+1)}>
						<circle id={'root_c'+(i+1)} key={'root_c'+(i+1)} className={getVisibility(0, tonic,scale,scaleType)} cx={(336*i)+25+50} cy={200+30} r="15" />
						<circle id={'root_cSharp'+(i+1)} key={'root_cSharp'+(i+1)} className={getVisibility(1, tonic,scale,scaleType)} cx={(336*i)+43+50} cy={130+30} r="13" stroke="#ffffff" />
						<circle id={'root_d'+(i+1)} key={'root_d'+(i+1)} className={getVisibility(2, tonic,scale,scaleType)} cx={(336*i)+73+50} cy={200+30} r="15" />
						<circle id={'root_dSharp'+(i+1)} key={'root_dSharp'+(i+1)} className={getVisibility(3, tonic,scale,scaleType)} cx={(336*i)+103+50} cy={130+30} r="13" stroke="#ffffff" />
						<circle id={'root_e'+(i+1)} key={'root_e'+(i+1)} className={getVisibility(4, tonic,scale,scaleType)} cx={(336*i)+121+50} cy={200+30} r="15" />
						<circle id={'root_f'+(i+1)} key={'root_f'+(i+1)} className={getVisibility(5, tonic,scale,scaleType)} cx={(336*i)+169+50} cy={200+30} r="15" />
						<circle id={'root_fSharp'+(i+1)} key={'root_fSharp'+(i+1)} className={getVisibility(6, tonic,scale,scaleType)} cx={(336*i)+187+50} cy={130+30} r="13" stroke="#ffffff" />
						<circle id={'root_g'+(i+1)} key={'root_g'+(i+1)} className={getVisibility(7, tonic,scale,scaleType)} cx={(336*i)+217+50} cy={200+30} r="15" />
						<circle id={'root_gSharp'+(i+1)} key={'root_gSharp'+(i+1)} className={getVisibility(8, tonic,scale,scaleType)} cx={(336*i)+241+50} cy={130+30} r="13" stroke="#ffffff" />
						<circle id={'root_a'+(i+1)} key={'root_a'+(i+1)} className={getVisibility(9, tonic,scale,scaleType)} cx={(336*i)+265+50} cy={200+30} r="15" />
						<circle id={'root_aSharp'+(i+1)} key={'root_aSharp'+(i+1)} className={getVisibility(10, tonic,scale,scaleType)} cx={(336*i)+295+50} cy={130+30} r="13" stroke="#ffffff" />
						<circle id={'root_b'+(i+1)} key={'root_b'+(i+1)} className={getVisibility(11, tonic,scale,scaleType)} cx={(336*i)+313+50} cy={200+30} r="15" />
					</g>
				)
			);
		}
	}
	
	class KeyNotes extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const keys = this.props.keys;
			const tonic = this.props.tonic;
			const scale = this.props.scale;
			
			function getVisibility(position,tonic) {
				let note = (position - tonic);
				//console.log('KeyNotes note: ' + note);
				while (note < 0) note += 12;
				//console.log('KeyNotes scale[note]: ' + scale[note]);
				return (
					scale[note] ? 'visible' : 'invisible'
				)
			}
			
			return (
				// Keyboard notes
				Array(keys).fill().map((octaveVal, i) =>
					<g stroke="none" strokeWidth="1" fill="#000000" key={'keyboardNotes'+(i+1)}>
						<circle id={'note_c'+(i+1)} key={'note_c'+(i+1)} className={getVisibility(0, tonic)} cx={(336*i)+25+50} cy={200+30} r="10" />
						<circle id={'note_cSharp'+(i+1)} key={'note_cSharp'+(i+1)} className={getVisibility(1, tonic)} cx={(336*i)+43+50} cy={130+30} r="10" fill="#ffffff" />
						<circle id={'note_d'+(i+1)} key={'note_d'+(i+1)} className={getVisibility(2, tonic)} cx={(336*i)+73+50} cy={200+30} r="10" />
						<circle id={'note_dSharp'+(i+1)} key={'note_dSharp'+(i+1)} className={getVisibility(3, tonic)} cx={(336*i)+103+50} cy={130+30} r="10" fill="#ffffff" />
						<circle id={'note_e'+(i+1)} key={'note_e'+(i+1)} className={getVisibility(4, tonic)} cx={(336*i)+121+50} cy={200+30} r="10" />
						<circle id={'note_f'+(i+1)} key={'note_f'+(i+1)} className={getVisibility(5, tonic)} cx={(336*i)+169+50} cy={200+30} r="10" />
						<circle id={'note_fSharp'+(i+1)} key={'note_fSharp'+(i+1)} className={getVisibility(6, tonic)} cx={(336*i)+187+50} cy={130+30} r="10" fill="#ffffff" />
						<circle id={'note_g'+(i+1)} key={'note_g'+(i+1)} className={getVisibility(7, tonic)} cx={(336*i)+217+50} cy={200+30} r="10" />
						<circle id={'note_gSharp'+(i+1)} key={'note_gSharp'+(i+1)} className={getVisibility(8, tonic)} cx={(336*i)+241+50} cy={130+30} r="10" fill="#ffffff" />
						<circle id={'note_a'+(i+1)} key={'note_a'+(i+1)} className={getVisibility(9, tonic)} cx={(336*i)+265+50} cy={200+30} r="10" />
						<circle id={'note_aSharp'+(i+1)} key={'note_aSharp'+(i+1)} className={getVisibility(10, tonic)} cx={(336*i)+295+50} cy={130+30} r="10" fill="#ffffff" />
						<circle id={'note_b'+(i+1)} key={'note_b'+(i+1)} className={getVisibility(11, tonic)} cx={(336*i)+313+50} cy={200+30} r="10" />
					</g>
				)
			);
		}
	}
	
	class KeyNoteLabels extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const keys = this.props.keys;
			const tonic = this.props.tonic;
			const tonicIndex = this.props.tonicIndex;
			const scaleType = this.props.scaleType;
			const labelScale = this.props.labelScale;
			const labelType = this.props.labelType;
			
			function getVisibility(position,tonic) {
				let noteLabel = (position - tonic);
				//console.log('KeyNoteLabels noteLabel: ' + noteLabel);
				while (noteLabel < 0) noteLabel += 12;
				//console.log('KeyNoteLabels labelScale[noteLabel]: ' + labelScale[noteLabel]);
				return (
					labelScale[noteLabel] ? 'visible' : 'invisible'
				)
			}
			
			function getLabel(position,tonicIndex,tonic,scaleType,labelType) {
				let numLabel = (position - tonic);
				while (numLabel < 0) numLabel += 12;
				//console.log('KeyNoteLabels getLabel labelScale: '+labelScale);
				//console.log('KeyNoteLabels getLabel labelType: '+labelType);
				let label = labelScale[numLabel];
				let scaleNotes;
				if (labelType == 1) { // switch to ABC
					//console.log('415 tonicIndex: '+tonicIndex+', scaleType: '+scaleType+', labelType: '+labelType);
					//label = notes_flat[position];
					if (scaleType == 1 || scaleType == 2) scaleNotes = notes_minTonics[tonicIndex][2];
					else if (scaleType == 3 || scaleType == 4) scaleNotes = notes_majTonics[tonicIndex][2];
					//console.log('421 label: '+eval(scaleNotes[position]));
					label = eval(scaleNotes)[position];
				}
				//console.log('KeyNoteLabels getLabel label: '+label);
				return (
					label
				)
			}
			
			function getDy(labelType) {
				let dy = 6;
				if (labelType == 1) {
					dy = 4;
				}
				return (
					dy
				)
			}
			
			function getFontSize(labelType) {
				let fontSize = '1rem';
				if (labelType == 1) {
					fontSize = '0.7rem';
				}
				return (
					fontSize
				)
			}
			
			return (
				// Keyboard noteLabels
				Array(keys).fill().map((octaveVal, i) =>
					<g stroke="none" strokeWidth="1" fontFamily="sans-serif" textAnchor="middle" stroke="none" fill="#ffffff" key={'keyboardNoteLabels'+(i+1)}>
						<text id={'label_c'+(i+1)} key={'label_c'+(i+1)} className={getVisibility(0, tonic)} x={(336*i)+25+50} y={200+30} dx="0" dy={getDy(labelType)} fontSize={getFontSize(labelType)}>{getLabel(0,tonicIndex,tonic,scaleType,labelType)}</text>
						<text id={'label_cSharp'+(i+1)} key={'label_cSharp'+(i+1)} className={getVisibility(1, tonic)} x={(336*i)+43+50} y={130+30} dx="0" dy={getDy(labelType)} fontSize={getFontSize(labelType)} fill="#000000">{getLabel(1,tonicIndex,tonic,scaleType,labelType)}</text>
						<text id={'label_d'+(i+1)} key={'label_d'+(i+1)} className={getVisibility(2, tonic)} x={(336*i)+73+50} y={200+30} dx="0" dy={getDy(labelType)} fontSize={getFontSize(labelType)}>{getLabel(2,tonicIndex,tonic,scaleType,labelType)}</text>
						<text id={'label_dSharp'+(i+1)} key={'label_dSharp'+(i+1)} className={getVisibility(3, tonic)} x={(336*i)+103+50} y={130+30} dx="0" dy={getDy(labelType)} fontSize={getFontSize(labelType)} fill="#000000">{getLabel(3,tonicIndex,tonic,scaleType,labelType)}</text>
						<text id={'label_e'+(i+1)} key={'label_e'+(i+1)} className={getVisibility(4, tonic)} x={(336*i)+121+50} y={200+30} dx="0" dy={getDy(labelType)} fontSize={getFontSize(labelType)}>{getLabel(4,tonicIndex,tonic,scaleType,labelType)}</text>
						<text id={'label_f'+(i+1)} key={'label_f'+(i+1)} className={getVisibility(5, tonic)} x={(336*i)+169+50} y={200+30} dx="0" dy={getDy(labelType)} fontSize={getFontSize(labelType)}>{getLabel(5,tonicIndex,tonic,scaleType,labelType)}</text>
						<text id={'label_fSharp'+(i+1)} key={'label_fSharp'+(i+1)} className={getVisibility(6, tonic)} x={(336*i)+187+50} y={130+30} dx="0" dy={getDy(labelType)} fontSize={getFontSize(labelType)} fill="#000000">{getLabel(6,tonicIndex,tonic,scaleType,labelType)}</text>
						<text id={'label_g'+(i+1)} key={'label_g'+(i+1)} className={getVisibility(7, tonic)} x={(336*i)+217+50} y={200+30} dx="0" dy={getDy(labelType)} fontSize={getFontSize(labelType)}>{getLabel(7,tonicIndex,tonic,scaleType,labelType)}</text>
						<text id={'label_gSharp'+(i+1)} key={'label_gSharp'+(i+1)} className={getVisibility(8, tonic)} x={(336*i)+241+50} y={130+30} dx="0" dy={getDy(labelType)} fontSize={getFontSize(labelType)} fill="#000000">{getLabel(8,tonicIndex,tonic,scaleType,labelType)}</text>
						<text id={'label_a'+(i+1)} key={'label_a'+(i+1)} className={getVisibility(9, tonic)} x={(336*i)+265+50} y={200+30} dx="0" dy={getDy(labelType)} fontSize={getFontSize(labelType)}>{getLabel(9,tonicIndex,tonic,scaleType,labelType)}</text>
						<text id={'label_aSharp'+(i+1)} key={'label_aSharp'+(i+1)} className={getVisibility(10, tonic)} x={(336*i)+295+50} y={130+30} dx="0" dy={getDy(labelType)} fontSize={getFontSize(labelType)} fill="#000000">{getLabel(10,tonicIndex,tonic,scaleType,labelType)}</text>
						<text id={'label_b'+(i+1)} key={'label_b'+(i+1)} className={getVisibility(11, tonic)} x={(336*i)+313+50} y={200+30} dx="0" dy={getDy(labelType)} fontSize={getFontSize(labelType)}>{getLabel(11,tonicIndex,tonic,scaleType,labelType)}</text>
					</g>
				)
			);
		}
	}
	
	class KeyboardLengthControl extends React.Component {
		constructor(props) {
			super(props);
			this.handleChange = this.handleChange.bind(this);
		}

		handleChange(e) {
			this.props.onKeyboardLengthChange(e.target.value);
		}
		
		render() {
			const instrument = this.props.instrument;
			const keys = this.props.value;
			
			function getVisibility(instrument) {
				return (
					(instrument == 'keyboard') ? 'visible' : 'invisible'
				)
			}
			
			return (
				<div id="keyLengthSelectorSection" className={getVisibility(instrument)}>
					<form id="keyLengthSelector">
						<label id="keyboardLengthLabel" htmlFor="keyboardLengthRange" >Keyboard Length</label><br />
						<input id="keyboardLengthRange" type="range" defaultValue={keys} onChange={this.handleChange} min="1" max="7" /> 
					</form>
				</div>
			);
		}
	}
	
	class Fretboard extends React.Component {
		constructor(props) {
			super(props);
		}
		render() {
			const instrument = this.props.instrument;
			const frets = this.props.frets;
			const tonic = this.props.tonic;
			const tonicIndex = this.props.tonicIndex;
			const labelType = this.props.labelType;
			const scale = this.props.scale;
			const scaleType = this.props.scaleType;
			const labelScale = this.props.labelScale;
			const labelDensity = this.props.labelDensity;
			
			function getVisibility(instrument) {
				return (
					(instrument == 'guitar') ? 'visible' : 'invisible'
				)
			}
			
			return (
				<div id="fretboardContainer" className={getVisibility(instrument)}>
					<svg id="svgStandardGuitar" className="svgContainer" height="320" width={(frets * 100) + 100}>
						<PositionMarkers frets={frets} />
						<NeckOutline frets={frets} />
						<FretGrid frets={frets} />
						<FretNut />
						<FretRootNotes frets={frets} tonic={tonic} scale={scale} scaleType={scaleType} />
						<FretNotes frets={frets} tonic={tonic} scale={scale} />
						<FretNoteLabels frets={frets} tonic={tonic} tonicIndex={tonicIndex} scale={scale} scaleType={scaleType} labelType={labelType} labelScale={labelScale} />
						Sorry, your browser does not support inline SVG.
					</svg>
				</div>
			);
		}
	}
	
	class PositionMarkers extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const frets = this.props.frets;
			
			function getVisibility(position,inlayType) {
				const positionArray = [3,5,7,9,15,17,19,21];
				const octaveArray = [12,24];
				//console.log('537 positionArray.indexOf(position): '+positionArray.indexOf(position));
				//console.log('538 octaveArray.indexOf(position): '+octaveArray.indexOf(position));
				//console.log('539 inlayType: '+inlayType);
				let visibility = 'invisible';
				if (inlayType === 'regular') {
					if (positionArray.indexOf(position) !== -1) visibility = 'visible';
				} else if (inlayType === 'octave') {
						if (octaveArray.indexOf(position) !== -1) visibility = 'visible';
				} else {
					visibility = 'invisible';
				}
				
				return (
					 visibility
				)
			}
			
			return (
				<g strokeWidth="0" stroke="none" fill="#dddddd" key={'inlays_'+(1)}>
					{Array(frets).fill().map((rowVal, i) => 
						<circle id={'inlay'+'_'+(i+1)} key={'inlay'+'_'+(i+1)} className={getVisibility(i,'regular')} cx={(i*100)+49} cy={125+40} r="8" />
					)}
					{Array(frets).fill().map((rowVal, i) => 
						<circle id={'octInlayTop'+'_'+(i+1)} key={'octInlayTop'+'_'+(i+1)} className={getVisibility(i,'octave')} cx={(i*100)+49} cy={75+40} r="8" />
					)}
					{Array(frets).fill().map((rowVal, i) => 
						<circle id={'octInlayBottom'+'_'+(i+1)} key={'octInlayBottom'+'_'+(i+1)} className={getVisibility(i,'octave')} cx={(i*100)+49} cy={175+40} r="8" />
					)}
				</g>
			);
		}
	}
	
	class NeckOutline extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const frets = this.props.frets;
			
			return (
				<g stroke="#dddddd" strokeWidth="2" fill="none" key={'neckOutline'+(1)}>
					{Array(frets).fill().map((rowVal, i) => <rect id={'neckTop'+'_'+(i+1)} key={'neckTop'+'_'+(i+1)} x={(i*100)-1} y={(0)+30} width="100" height="10" /> )}
					{Array(frets).fill().map((rowVal, i) => <rect id={'neckBottom'+'_'+(i+1)} key={'neckBottom'+'_'+(i+1)} x={(i*100)-1} y={(250)+40} width="100" height="10" /> )}
				</g>
			);
		}
	}
	
	class FretGrid extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const frets = this.props.frets;
			
			return (
				Array(5).fill().map((fretVal, ii) =>
					<g stroke="#000000" strokeWidth="2" fill="none" key={'fret'+(ii+1)}>
						{Array(frets).fill().map((rowVal, i) => <rect id={'fret'+(ii+1)+'_'+(i+1)} key={'fret'+(ii+1)+'_'+(i+1)} x={(i*100)-1} y={(ii*50)+40} width="100" height="50" /> )}
					</g>
				)
			);
		}
	}
	
	class FretNut extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			return (
				<g stroke="#999999" strokeWidth="2" fill="none" key="nut-outline">
					<rect id="guitar-nut" key="guitar-nut" x="74" y="30" width="25" height="270" />
				</g>
			);
		}
	}
	
	function standardTuning(notePosition,stringNum) {
		switch (stringNum) {
			case 0 : 
			notePosition += 4;
			break;
			case 1 : 
			notePosition += 11;
			break;
			case 2 : 
			notePosition += 7;
			break;
			case 3 : 
			notePosition += 2;
			break;
			case 4 : 
			notePosition += 9;
			break;
			case 5 : 
			notePosition += 4;
			break;
		}
		while (notePosition > 11) notePosition -= 12;
		return (
			notePosition
		)
	}
	
	class FretRootNotes extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const frets = this.props.frets;
			const tonic = this.props.tonic;
			const scale = this.props.scale;
			const scaleType = this.props.scaleType;
			
			function getVisibility(position,stringNum,tonic,scale,scaleType) {
				let note = standardTuning(position,stringNum);
				note -= tonic;
				//console.log('FretRootNotes note: ' + note);
				while (note < 0) note += 12;
				//console.log('FretRootNotes scale[note]: ' + scale[note]);
				
				return (
					(scale[note] === 1) && (scaleType != '1') && (scaleType != '4') ? 'visible' : 'invisible' // cancels root circle for dim & aug
				)
			}
			
			return (
				// Guitar fretboard RootNotes; ii = stringNum, i = position
				Array(6).fill().map((rootVal, ii) =>
					<g stroke="black" strokeWidth="2" fill="none" key={'root'+(ii+1)}>
						{Array(frets).fill().map((rowVal, i) => <circle id={'root'+(ii+1)+'_'+(i+1)} key={'root'+(ii+1)+'_'+(i+1)} className={getVisibility(i,ii,tonic,scale,scaleType)} cx={(i*100)+49} cy={(ii*50)+40} r="15" /> )}
					</g>
				)
			);
		}
	}
	
	class FretNotes extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const frets = this.props.frets;
			const tonic = this.props.tonic;
			const scale = this.props.scale;
			
			function getVisibility(position,stringNum,tonic) {
				let note = standardTuning(position,stringNum);
				note -= tonic;
				//console.log('FretNotes note: ' + note);
				while (note < 0) note += 12;
				//console.log('FretNotes scale[note]: ' + scale[note]);
				
				return (
					scale[note] ? 'visible' : 'invisible'
				)
			}
			
			return (
				// Guitar fretboard Notes; ii = stringNum, i = position
				Array(6).fill().map((noteVal, ii) =>
					<g stroke="none" fill="black" key={'note'+(ii+1)}>
						{Array(frets).fill().map((rowVal, i) => <circle id={'note'+(ii+1)+'_'+(i+1)} key={'note'+(ii+1)+'_'+(i+1)} className={getVisibility(i,ii,tonic)} cx={(i*100)+49} cy={(ii*50)+40} r="10" /> )}
					</g>
				)
			);
		}
	}
	
	class FretNoteLabels extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const frets = this.props.frets;
			const tonic = this.props.tonic;
			const tonicIndex = this.props.tonicIndex;
			const scaleType = this.props.scaleType;
			const labelScale = this.props.labelScale;
			const labelType = this.props.labelType;
			//console.log('673 labelType: '+labelType+', tonicIndex: '+tonicIndex+', scaleType: '+scaleType);
			
			function getVisibility(position,stringNum,tonic) {
				let noteLabel = standardTuning(position,stringNum);
				noteLabel -= tonic;
				//console.log('FretNoteLabels noteLabel: ' + noteLabel);
				while (noteLabel < 0) noteLabel += 12;
				//console.log('FretNoteLabels scale[noteLabel]: ' + labelScale[noteLabel]);
				return (
					labelScale[noteLabel] ? 'visible' : 'invisible'
				)
			}
			
			function getLabel(position,stringNum,tonicIndex,tonic,scaleType,labelType) {
				let numLabel = standardTuning(position,stringNum);
				let alphaLabel = numLabel;
				numLabel -= tonic;
				while (numLabel < 0) numLabel += 12;
				let label = labelScale[numLabel];
				//console.log('693 label: '+label);
				let scaleNotes;
				if (labelType == 1) { // switch to ABC
					//console.log('697 tonicIndex: '+tonicIndex+', scaleType: '+scaleType+', labelType: '+labelType);
					if (scaleType == 1 || scaleType == 2) scaleNotes = notes_minTonics[tonicIndex][2];
					else if (scaleType == 3 || scaleType == 4) scaleNotes = notes_majTonics[tonicIndex][2];
					//console.log('421 label: '+eval(scaleNotes[position]));
					label = eval(scaleNotes)[alphaLabel];
				}
				return (
					label
				)
			}
			
			function getDy(labelType) {
				let dy = 6;
				if (labelType == 1) {
					dy = 5;
				}
				return (
					dy
				)
			}
			
			function getFontSize(labelType) {
				let fontSize = '1rem';
				if (labelType == 1) {
					fontSize = '0.7rem';
				}
				return (
					fontSize
				)
			}
			
			return (
				// Guitar fretboard NoteLabels; ii = stringNum, i = position
				Array(6).fill().map((labelVal, ii) =>
					<g fontSize="1rem" fontFamily="sans-serif" fill="#ffffff" stroke="none" textAnchor="middle" key={'label'+(ii+1)}>
						{Array(frets).fill().map((rowVal, i) => <text id={'label'+(ii+1)+'_'+(i+1)} key={'label'+(ii+1)+'_'+(i+1)} className={getVisibility(i,ii,tonic)} x={(i*100)+49} y={(ii*50)+40} dx="0" dy={getDy(labelType)} fontSize={getFontSize(labelType)}>{getLabel(i,ii,tonicIndex,tonic,scaleType,labelType)}</text> )}
					</g>
				)
			);
		}
	}
	
	class FretLengthControl extends React.Component {
		constructor(props) {
			super(props);
			this.handleChange = this.handleChange.bind(this);
		}

		handleChange(e) {
			this.props.onFretboardLengthChange(e.target.value);
		}

		render() {
			const instrument = this.props.instrument;
			const frets = this.props.value;
			
			function getVisibility(instrument) {
				return (
					(instrument == 'guitar') ? 'visible' : 'invisible'
				)
			}
			
			return (
				<div id="lengthSelectorSection" className={getVisibility(instrument)}>
					<form id="lengthSelector">
						<label id="fretboardLengthLabel" htmlFor="fretboardLengthRange" >Fretboard Length</label><br />
						<input id="fretboardLengthRange" type="range" defaultValue={frets} onChange={this.handleChange} min="5" max="25" /> 
					</form>
				</div>
			);
		}
	}
	
	class BassFretboard extends React.Component {
		constructor(props) {
			super(props);
		}
		render() {
			const instrument = this.props.instrument;
			const frets = this.props.frets;
			const tonic = this.props.tonic;
			const tonicIndex = this.props.tonicIndex;
			const labelType = this.props.labelType;
			const scale = this.props.scale;
			const scaleType = this.props.scaleType;
			const labelScale = this.props.labelScale;
			const labelDensity = this.props.labelDensity;
			
			function getVisibility(instrument) {
				return (
					(instrument == 'bass') ? 'visible' : 'invisible'
				)
			}
			
			return (
				<div id="bassFretboardContainer" className={getVisibility(instrument)}>
					<svg id="svgBassGuitar" className="svgContainer" height="295" width={(frets * 100) + 100}>
						<BassPositionMarkers frets={frets} />
						<BassNeckOutline frets={frets} />
						<BassFretGrid frets={frets} />
						<BassFretNut />
						<BassFretRootNotes frets={frets} tonic={tonic} scale={scale} scaleType={scaleType} />
						<BassFretNotes frets={frets} tonic={tonic} scale={scale} />
						<BassFretNoteLabels frets={frets} tonic={tonic} tonicIndex={tonicIndex} scale={scale} scaleType={scaleType} labelType={labelType} labelScale={labelScale} />
						Sorry, your browser does not support inline SVG.
					</svg>
				</div>
			);
		}
	}
	
	class BassPositionMarkers extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const frets = this.props.frets;
			
			function getVisibility(position,inlayType) {
				const positionArray = [3,5,7,9,15,17,19,21];
				const octaveArray = [12,24];
				
				let visibility = 'invisible';
				if (inlayType === 'regular') {
					if (positionArray.indexOf(position) !== -1) visibility = 'visible';
				} else if (inlayType === 'octave') {
						if (octaveArray.indexOf(position) !== -1) visibility = 'visible';
				} else {
					visibility = 'invisible';
				}
				
				return (
					 visibility
				)
			}
			
			return (
				<g strokeWidth="0" stroke="none" fill="#dddddd" key={'inlays_'+(1)}>
					{Array(frets).fill().map((rowVal, i) => 
						<circle id={'inlay'+'_'+(i+1)} key={'inlay'+'_'+(i+1)} className={getVisibility(i,'regular')} cx={(i*100)+49} cy={75+78} r="8" />
					)}
					{Array(frets).fill().map((rowVal, i) => 
						<circle id={'octInlayTop'+'_'+(i+1)} key={'octInlayTop'+'_'+(i+1)} className={getVisibility(i,'octave')} cx={(i*100)+49} cy={25+53} r="8" />
					)}
					{Array(frets).fill().map((rowVal, i) => 
						<circle id={'octInlayBottom'+'_'+(i+1)} key={'octInlayBottom'+'_'+(i+1)} className={getVisibility(i,'octave')} cx={(i*100)+49} cy={125+103} r="8" />
					)}
				</g>
			);
		}
	}
	
	class BassNeckOutline extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const frets = this.props.frets;
			
			return (
				<g stroke="#dddddd" strokeWidth="4" fill="none" key={'neckOutline'+(1)}>
					{Array(frets).fill().map((rowVal, i) => <rect id={'neckTop'+'_'+(i+1)} key={'neckTop'+'_'+(i+1)} x={(i*100)-2} y={(0)+30} width="100" height="10" /> )}
					{Array(frets).fill().map((rowVal, i) => <rect id={'neckBottom'+'_'+(i+1)} key={'neckBottom'+'_'+(i+1)} x={(i*100)-2} y={(225)+40} width="100" height="10" /> )}
				</g>
			);
		}
	}
	
	class BassFretGrid extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const frets = this.props.frets;
			
			return (
				Array(3).fill().map((fretVal, ii) =>
					<g stroke="#000000" strokeWidth="4" fill="none" key={'fret'+(ii+1)}>
						{Array(frets).fill().map((rowVal, i) => <rect id={'fret'+(ii+1)+'_'+(i+1)} key={'fret'+(ii+1)+'_'+(i+1)} x={(i*100)-2} y={(ii*75)+40} width="100" height="75" /> )}
					</g>
				)
			);
		}
	}
	
	class BassFretNut extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			return (
				<g stroke="#999999" strokeWidth="4" fill="none" key="nut-outline">
					<rect id="guitar-nut" key="guitar-nut" x="73" y="30" width="25" height="245" />
				</g>
			);
		}
	}
	
	function bassTuning(notePosition,stringNum) {
		switch (stringNum) {
			case 0 : 
			notePosition += 7;
			break;
			case 1 : 
			notePosition += 2;
			break;
			case 2 : 
			notePosition += 9;
			break;
			case 3 : 
			notePosition += 4;
			break;
		}
		while (notePosition > 11) notePosition -= 12;
		return (
			notePosition
		)
	}
	
	class BassFretRootNotes extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const frets = this.props.frets;
			const tonic = this.props.tonic;
			const scale = this.props.scale;
			const scaleType = this.props.scaleType;
			
			function getVisibility(position,stringNum,tonic,scale,scaleType) {
				let note = bassTuning(position,stringNum);
				note -= tonic;
				while (note < 0) note += 12;
				
				return (
					(scale[note] === 1) && (scaleType != '1') && (scaleType != '4') ? 'visible' : 'invisible' // cancels root circle for dim & aug
				)
			}
			
			return (
				// Bass Guitar fretboard RootNotes; ii = stringNum, i = position
				Array(4).fill().map((rootVal, ii) =>
					<g stroke="black" strokeWidth="2" fill="none" key={'root'+(ii+1)}>
						{Array(frets).fill().map((rowVal, i) => <circle id={'root'+(ii+1)+'_'+(i+1)} key={'root'+(ii+1)+'_'+(i+1)} className={getVisibility(i,ii,tonic,scale,scaleType)} cx={(i*100)+49} cy={(ii*75)+40} r="15" /> )}
					</g>
				)
			);
		}
	}
	
	class BassFretNotes extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const frets = this.props.frets;
			const tonic = this.props.tonic;
			const scale = this.props.scale;
			
			function getVisibility(position,stringNum,tonic) {
				let note = bassTuning(position,stringNum);
				note -= tonic;
				while (note < 0) note += 12;
				
				return (
					scale[note] ? 'visible' : 'invisible'
				)
			}
			
			return (
				// Bass Guitar fretboard Notes; ii = stringNum, i = position
				Array(4).fill().map((noteVal, ii) =>
					<g stroke="none" fill="black" key={'note'+(ii+1)}>
						{Array(frets).fill().map((rowVal, i) => <circle id={'note'+(ii+1)+'_'+(i+1)} key={'note'+(ii+1)+'_'+(i+1)} className={getVisibility(i,ii,tonic)} cx={(i*100)+49} cy={(ii*75)+40} r="10" /> )}
					</g>
				)
			);
		}
	}
	
	class BassFretNoteLabels extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const frets = this.props.frets;
			const tonic = this.props.tonic;
			const tonicIndex = this.props.tonicIndex;
			const scaleType = this.props.scaleType;
			const labelScale = this.props.labelScale;
			const labelType = this.props.labelType;
			
			function getVisibility(position,stringNum,tonic) {
				let noteLabel = bassTuning(position,stringNum);
				noteLabel -= tonic;
				while (noteLabel < 0) noteLabel += 12;
				return (
					labelScale[noteLabel] ? 'visible' : 'invisible'
				)
			}
			
			function getLabel(position,stringNum,tonicIndex,tonic,scaleType,labelType) {
				let numLabel = bassTuning(position,stringNum);
				let alphaLabel = numLabel;
				numLabel -= tonic;
				while (numLabel < 0) numLabel += 12;
				let label = labelScale[numLabel];
				let scaleNotes;
				if (labelType == 1) { // switch to ABC
					if (scaleType == 1 || scaleType == 2) scaleNotes = notes_minTonics[tonicIndex][2];
					else if (scaleType == 3 || scaleType == 4) scaleNotes = notes_majTonics[tonicIndex][2];
					label = eval(scaleNotes)[alphaLabel];
				}
				return (
					label
				)
			}
			
			function getDy(labelType) {
				let dy = 6;
				if (labelType == 1) {
					dy = 5;
				}
				return (
					dy
				)
			}
			
			function getFontSize(labelType) {
				let fontSize = '1rem';
				if (labelType == 1) {
					fontSize = '0.7rem';
				}
				return (
					fontSize
				)
			}
			
			return (
				// Bass Guitar fretboard NoteLabels; ii = stringNum, i = position
				Array(4).fill().map((labelVal, ii) =>
					<g fontSize="1rem" fontFamily="sans-serif" fill="#ffffff" stroke="none" textAnchor="middle" key={'label'+(ii+1)}>
						{Array(frets).fill().map((rowVal, i) => <text id={'label'+(ii+1)+'_'+(i+1)} key={'label'+(ii+1)+'_'+(i+1)} className={getVisibility(i,ii,tonic)} x={(i*100)+49} y={(ii*75)+40} dx="0" dy={getDy(labelType)} fontSize={getFontSize(labelType)}>{getLabel(i,ii,tonicIndex,tonic,scaleType,labelType)}</text> )}
					</g>
				)
			);
		}
	}
	
	class BassFretLengthControl extends React.Component {
		constructor(props) {
			super(props);
			this.handleChange = this.handleChange.bind(this);
		}

		handleChange(e) {
			this.props.onFretboardLengthChange(e.target.value);
		}

		render() {
			const instrument = this.props.instrument;
			const frets = this.props.value;
			
			function getVisibility(instrument) {
				return (
					(instrument == 'bass') ? 'visible' : 'invisible'
				)
			}
			
			return (
				<div id="bassLengthSelectorSection" className={getVisibility(instrument)}>
					<form id="bassLengthSelector">
						<label id="bassFretboardLengthLabel" htmlFor="bassFretboardLengthRange" >Fretboard Length</label><br />
						<input id="bassFretboardLengthRange" type="range" defaultValue={frets} onChange={this.handleChange} min="5" max="25" /> 
					</form>
				</div>
			);
		}
	}
	
	class Caption extends React.Component {
		constructor(props) {
			super(props);
		}

		render() {
			const tonicIndex = this.props.tonicIndex;
			//console.log('736 tonicIndex: '+tonicIndex);
			const scaleType = this.props.scaleType;
			const density = this.props.density - 1;
			const description = eval('captions['+(scaleType-1)+']['+density+']');
			
			//let noteLetter = eval('notes_flat['+tonic+']');
			let tonicName;
			if (scaleType == 1 || scaleType == 2) tonicName = notes_minTonics[tonicIndex][0];
			else if (scaleType == 3 || scaleType == 4) tonicName = notes_majTonics[tonicIndex][0];
			//console.log('noteLetter: '+noteLetter);
			return (
				<h2 id="caption">{tonicName} {description}</h2>
			);
		}
	}
	
	class TonicControl extends React.Component {
		constructor(props) {
			super(props);
			this.handleChange = this.handleChange.bind(this);
		}

		handleChange(e) {
			//console.log('handleChange e.target.value: '+e.target.value);
			this.props.onTonicIndexChange(e.target.value);
		}

		render() {
			const tonicIndex = this.props.value;
			const tonic = this.props.tonic;
			//console.log('TonicControl tonicIndex: '+tonicIndex);
			const scaleType = this.props.scaleType;
			
			function getTonicName(tonicIndex,scaleType) {
				//console.log('TonicControl getTonicName tonic: '+tonic);
				//console.log('TonicControl getTonicName scaleType: '+scaleType);
				//console.log('TonicControl getTonicName tonicName: '+notes_minTonics[4][0]);
				let tonicName;
				if (scaleType == 1 || scaleType == 2) tonicName = notes_minTonics[tonicIndex][0];
				else if (scaleType == 3 || scaleType == 4) tonicName = notes_majTonics[tonicIndex][0];
				//console.log('TonicControl getTonicName tonic: '+tonic);
				return (
					tonicName
				)
			}
			
			return (
				<div id="tonic-selector-section">
					<div id="tonic-controls-wrapper">
						<button id="tonic-back" className="tonic-controls" value={-1} onClick={this.handleChange}>{'<'}</button><div id="tonicDisplay">{getTonicName(tonicIndex,scaleType)}</div><button id="tonic-fwd" className="tonic-controls" value={1} onClick={this.handleChange}>{'>'}</button>
					</div>
				</div>
			);
		}
	}
	
	class ScaleTypeControl extends React.Component {
		constructor(props) {
			super(props);
			this.handleChange = this.handleChange.bind(this);
		}

		handleChange(e) {
			this.props.onScaleTypeChange(e.target.value);
		}

		render() {
			const scaleType = this.props.value;
			return (
				<div id="scaleTypeSelectorSection">
					<form id="scaleTypeSelector">
						<label id="scaleTypeLabel" htmlFor="scaleTypeRange" >Dim7 - Min - Maj - Aug</label><br />
						<input id="scaleTypeRange" type="range" defaultValue={scaleType} onChange={this.handleChange} min="1" max="4" /> 
					</form>
				</div>
			);
		}
	}
	
	class ScaleDensityControl extends React.Component {
		constructor(props) {
			super(props);
			this.handleChange = this.handleChange.bind(this);
		}

		handleChange(e) {
			this.props.onScaleDensityChange(e.target.value);
		}

		render() {
			const scaleDensity = this.props.value;
			const scaleDensityMax = this.props.max;
			return (
				<div id="densitySelectorSection">
					<form id="densitySelectorForm">
						<label id="scaleDensityLabel" htmlFor="scaleDensityRange" >Tones</label><br />
						<input id="scaleDensityRange" type="range" defaultValue={scaleDensity} onChange={this.handleChange} min="1" max={scaleDensityMax} /> 
					</form>
				</div>
			);
		}
	}
	
	class LabelTypeControl extends React.Component {
		constructor(props) {
			super(props);
			this.handleChange = this.handleChange.bind(this);
		}

		handleChange(e) {
			this.props.onLabelTypeChange(e.target.value);
		}

		render() {
			const labelType = this.props.value;
			return (
				<div id="labelTypeSection">
					<form id="labelTypeForm">
						<label id="labelTypeLabel123" htmlFor="labelTypeRange" >123</label><input id="labelTypeRange" type="range" defaultValue={labelType} onChange={this.handleChange} min="0" max="1" /><label id="labelTypeLabelABC" htmlFor="labelTypeRange" >ABC</label>
					</form>
				</div>
			);
		}
	}
	
	class LabelDensityControl extends React.Component {
		constructor(props) {
			super(props);
			this.handleChange = this.handleChange.bind(this);
		}

		handleChange(e) {
			this.props.onLabelDensityChange(e.target.value);
		}

		render() {
			const labelDensity = this.props.value;
			const labelDensityMax = this.props.max;
			return (
				<div id="labelSelectorSection">
					<form id="labelSelectorForm">
						<label id="labelDensityLabel" htmlFor="noteLabelRange" >Labels</label><br />
						<input id="labelDensityRange" type="range" defaultValue={labelDensity} onChange={this.handleChange} min="0" max={labelDensityMax} />
					</form>
				</div>
			);
		}
	}
	
  return (
    <div className="App">
			<div id="logoWrapper">
				<div id="logo">Music Hacker</div>
				<div id="tagline">Tools for Musicians</div>
			</div>
			
			<MusicHackerApp />
			
			<div className="footer">
				&copy; 2023 <strong>360 Digital Arts</strong>. All Rights Reserved. App design by Mark A. Engel.
			</div>
    </div>

  );
}

export default App;
